import React from "react";
import Head from "next/head";
import MuiBox from "@material-ui/core/Box";
import { useTheme } from "lib/contexts/themeContext";
import PageLayout from "lib/components/PageLayout";
import IconNoMatch from "lib/assets/no-match.svg";

/**
 * 404
 */
const NotFound = () => {
  const theme = useTheme();

  return (
    <PageLayout>
      <MuiBox
        py={20}
        bgcolor={theme.palette.blueGrey[100]}
        flex={1}
        width={1}
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Head>
          <title>Not found</title>
        </Head>
        <MuiBox pb="16px" fontSize={30} fontWeight="bold" lineHeight={1.07}>
          404 Not Found
        </MuiBox>
        <MuiBox pb={6} lineHeight={2.29} fontSize={14}>
          The requested URL was not found on this server.
        </MuiBox>
        <MuiBox
          overflow="hidden"
          borderRadius={150}
          width={300}
          height={300}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor={theme.palette.common.white}
        >
          <IconNoMatch />
        </MuiBox>
      </MuiBox>
    </PageLayout>
  );
};

export default NotFound;
